<template>
  <v-container class="ma-0 pa-0" fluid fill-height>
    
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="expandButton && $vuetify.breakpoint.lgAndUp && role != 5" v-bind="attrs" v-on="on" :class="
            $vuetify.breakpoint.lgOnly ? 'expand-button-lg' : 'expand-button'
          " class="mb-3 ml-4" fab dark small color="primary" @click="expandDashboard()">
          <v-icon color="white"> mdi-chevron-double-left </v-icon>
        </v-btn>
      </template>
      <span>View Full Table</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="shrinkButton" v-bind="attrs" v-on="on" class="mb-3 ml-4 close-button" fab dark x-small
          color="white" @click="shrinkDashboard()">
          <v-icon color="primary"> mdi-close </v-icon>
        </v-btn>
      </template>
      <span>Close Full Table</span>
    </v-tooltip>

    <div id="map" :class="$vuetify.breakpoint.lgAndUp? 'map' : 'map_mobile'">

      <!-- <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-show="expandButton" v-bind="attrs" v-on="on"
          class="mb-3 ml-4" fab dark small color="primary" @click="expandDashboard()">
            <v-icon color="white"> mdi-chevron-double-left </v-icon>
          </v-btn>
        </template>
        <span>View Full Table</span>
      </v-tooltip> -->

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-show="desktopView == false && !$vuetify.breakpoint.lgAndUp"
          class = "mapviewerBtn ma-5 primary" 
          elevation="2"
          color="#0082ba"
          fab
          small
          v-bind="attrs" 
          v-on="on"
          @click="desktopView = true;"
          >
            <v-icon color="white"> mdi-view-dashboard-outline </v-icon>
          </v-btn>
        </template>
        <span>View Dashboard</span>
      </v-tooltip>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-show="desktopView == false && !$vuetify.breakpoint.lgAndUp"
          class = "mapviewerBtn ma-5 primary" 
          elevation="2"
          color="#0082ba"
          fab
          small
          v-bind="attrs" 
          v-on="on"
          style="top:50px"
          @click="home();"
          >
            <v-icon color="white"> mdi-home </v-icon>
          </v-btn>
        </template>
        <span>Home</span>
      </v-tooltip>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-show="desktopView == false && !$vuetify.breakpoint.lgAndUp"
          class = "mapviewerBtn ma-5 primary" 
          elevation="2"
          color="#0082ba"
          fab
          small
          v-bind="attrs" 
          v-on="on"
          style="top:100px"
          @click="mapButton = !mapButton; infoButton = false;"
          >
            <v-icon color="white"> mdi-map </v-icon>
          </v-btn>
        </template>
        <span>Map View Options</span>
      </v-tooltip>

      <v-card class="class-card-map" v-show="mapButton">
        <div class="justify-center align-center primary white--text text-center pa-3 card-header">
          Map View Options
        </div>

        <template v-for="(item, index) in listMapViews">
          <v-row class="px-3" :key="index">
            <v-col cols="6" class="pa-4">
              <v-img :src="item.src" contain @click="changeBasemap(item.layer)"></v-img>
            </v-col>

            <v-col cols="6" class="pa-4 caption" @click="changeBasemap(item.layer)">
              {{ item.name }}
            </v-col>
          </v-row>
        </template>
      </v-card>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-show="desktopView == false && !$vuetify.breakpoint.lgAndUp"
          class = "mapviewerBtn ma-5 primary" 
          elevation="2"
          color="#0082ba"
          fab
          small
          v-bind="attrs" 
          v-on="on"
          style="top:150px"
          @click="infoButton = !infoButton; mapButton = false;"
          >
            <v-icon color="white"> mdi-information-outline </v-icon>
          </v-btn>
        </template>
        <span>Information</span>
      </v-tooltip>

      <v-card :class="
          $vuetify.breakpoint.lgAndUp
            ? 'class-card-info'
            : 'class-card-info-mobile'
        " v-show="infoButton">
        <div class="justify-center align-center primary white--text text-center pa-3 body-1 card-header">
          Information
        </div>

        <div class="text-center pa-3 body-2">Station Status</div>

        <template v-for="(item, index) in station_status">
          <v-row class="px-5" :key="'B' + index">
            <v-col cols="6" class="px-4 py-3 text-center">
              <v-img height="45px" :src="item.img" contain></v-img>
            </v-col>

            <v-col cols="6" class="'px-4 py-3 caption">
              {{ item.text }}
            </v-col>
          </v-row>
        </template>

        <!-- <div class="text-center pa-3">WQI Class</div>

        <template v-for="(item, index) in listInfoViews">
          <v-row class="px-5" :key="'A' + index">
            <v-col cols="6" class="px-4 py-1 text-center">
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-col>

            <v-col cols="6" class="px-4 py-1">
              {{ item.name }}
            </v-col>
          </v-row>
        </template> -->
      </v-card>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-show="desktopView == false && !$vuetify.breakpoint.lgAndUp"
          class = "mapviewerBtn ma-5 primary" 
          elevation="2"
          color="#0082ba"
          fab
          small
          v-bind="attrs" 
          v-on="on"
          style="top:150px"
          @click="displayPOI()"
          >
            <v-icon color="white"> mdi-map-marker </v-icon>
          </v-btn>
        </template>
        <span>Point Of Interest</span>
      </v-tooltip>


      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-show="desktopView && !$vuetify.breakpoint.lgAndUp"
          class = "mapviewerBtn ma-5 primary" 
          elevation="2"
          color="#0082ba"
          fab
          small
          v-bind="attrs" 
          v-on="on"
          @click="desktopView = false;"
          >
            <v-icon color="white"> mdi-map-legend </v-icon>
          </v-btn>
        </template>
        <span>View Map</span>
      </v-tooltip>

      

      <!-- <v-btn
            class = "mapviewerBtn ma-5 primary" 
            elevation="2"
            color="#0082ba"
            @click="goToPage('/Mapviewer')"
          ><v-icon
                dark
                left
              >
                mdi-map-legend
          </v-icon>View Full Map</v-btn> -->

      <!-- <v-btn
            class = "mapviewerBtn ma-10 primary" 
            elevation="2"
            color="#0082ba"
            @click="consoleSumtin()"
          ><v-icon
                dark
                left
              >
                mdi-map-legend
          </v-icon>Console Log</v-btn> -->

      <div 
      v-show="desktopView"
      :id="$vuetify.breakpoint.lgAndUp? 'dashboard' : 'dashboard_mobile'"
      :class="$vuetify.breakpoint.lgAndUp? '' : 'pt-15'"
      >
        <template>
          <v-data-table :headers="headers2" :items="tableContents" item-key="name"
            class="elevation-1 dashboard_table pa-2" :disable-pagination="true" :disable-filtering="true"
            :hide-default-footer="true" :hide-default-header="true" fixed-header
            :height="$vuetify.breakpoint.height - 80" disable-sort v-show="displayOne">
            <template v-slot:header="{ props }">
              <thead class="headers-class">
                <tr>
                  <template v-for="(item, key) in props.headers">
                    <th v-if="key == 0" style="line-height: normal" :key="key" :class="item.class">
                      {{ item.text }}
                    </th>
                    <th v-else style="line-height: normal" :key="key" :class="item.class" @click="
                        shrinkDashboard();
                        displayStatInfo(item.station);
                        getEquipmentDetail(item.station);
                        getWaterIntakeDetail(item.station);
                        station_id = item.station;
                      ">
                      {{ item.station }} , {{ item.location }} <br />
                      {{ new Date(item.datetime).toLocaleDateString("en-GB") }}
                      , {{ new Date(item.datetime).toLocaleTimeString() }}
                    </th>
                  </template>
                  <th v-show="shrinkButton == true || role == 5 ">Recommended Raw Water <br /> Quality Criteria
                  </th>
                  <th v-show="shrinkButton == true || role == 5  ">National Water <br /> Quality Standard</th>
                </tr>
              </thead>
            </template>

            <template v-slot:body="{ items }">
              <tbody class="text-center">
                <tr v-for="(item, key) in items" :key="key" :class="item.class">
                  <template v-if="item.parameter == 'WQI'">
                    <td class="text-start">{{ item.parameter }}</td>
                    <template v-for="(nestedItem, nestedKey) in getArray(item)">
                      <td class="text-start td-class" :key="nestedKey">
                        <v-chip :color="'class-' + nestedItem.class">
                          {{ nestedItem.value }}
                        </v-chip>
                      </td>
                    </template>

                    <!-- <template  v-for="(nestedItem, nestedKey) in item" >
                      <td   v-if= "nestedKey!= 'class'" :key="nestedKey" class="text-start">  {{ nestedItem }} </td>
                      </template> -->
                  </template>
                  <template v-else-if="item.parameter == 'Flowrate'">
                    <template v-for="(nestedItem, nestedKey, indexkey) in item">
                      <td v-if="indexkey == 0" :key="nestedKey" class="text-start fixed td-class">
                        {{ nestedItem }}
                      </td>
                      <td v-if="
                          nestedKey != 'class' &&
                          indexkey != 0 &&
                          nestedItem != 'NA'
                        " :key="nestedKey" class="text-start td-class">
                        {{ nestedItem }} m<sup>3</sup>/s
                      </td>
                      <td v-if="
                          nestedKey != 'class' &&
                          indexkey != 0 &&
                          nestedItem == 'NA'
                        " :key="nestedKey" class="text-start td-class">
                        {{ nestedItem }}
                      </td>
                    </template>
                  </template>
                  <template v-else>
                    <template v-for="(nestedItem, nestedKey, indexkey) in item">
                      <td v-if="indexkey == 0" :key="nestedKey" class="text-start fixed td-class" v-html="nestedItem">
                      </td>
                      <td v-if="nestedKey != 'class' && indexkey != 0" :key="nestedKey" class="text-start td-class"
                        v-html="nestedItem"></td>
                    </template>
                  </template>

                  <td class="text-start" v-show="shrinkButton == true || role == 5 ">
                    {{ recommended_raw_water_quality[key] }}
                  </td>

                  <td class="text-start" v-show="shrinkButton == true || role == 5  ">
                    {{ national_water_quality_standard[key] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </template>

        <v-card :class="
            $vuetify.breakpoint.lgAndUp
              ? 'mx-auto station-info-card pa-4'
              : 'mx-auto station-info-card-mobile'
          " outlined v-show="displayInformation" v-if="
            selectedFeature !== '' && selectedFeature.STATION_ID_A !== undefined
          " max-height="93%">
          <v-card-title class="pa-1 card-header">
            <v-spacer></v-spacer>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" dark icon x-small @click="
                    displayInformation = !displayInformation;
                    displayOne = !displayOne;
                    expandButton = true;
                    home();
                  ">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              Close
            </v-tooltip>

            <v-card-text v-if="selectedFeature !== ''">
              <v-list-item three-line dark class="pl-0 pr-0">
                <v-list-item-avatar class="mr-2">
                  <v-icon size="45px"> mdi-map-marker-multiple </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <span class="text-h6">STATION: {{ selectedFeature.STATION_ID_A }}</span>

                  <br />
                  Location : {{ selectedFeature.LOCATION }}
                  <br />

                  Last Updated :

                  {{
                  new Date(selectedFeature.DATETIME_A).toLocaleDateString(
                  "en-GB"
                  ) +
                  ", " +
                  new Date(selectedFeature.DATETIME_A).toLocaleTimeString()
                  }}
                </v-list-item-content>

                <v-list-item-content>
                  <v-img :lazy-src="selectedFeature.STATION_PICTURE" max-height="150" max-width="400"
                    :src="selectedFeature.STATION_PICTURE"></v-img>
                </v-list-item-content>

                <!-- ----------------COMMENT FOR THE TIME BEING---------------------- -->
                <!-- <v-list-item-content v-if="selectedFeature.PARAM_PH_CLASS === 'I'">
                  <div class="reading-info-card class-I">
                    <div class="text-h4" id="info_reading">
                      {{ selectedFeature.PARAM_PH }}
                    </div>
                    <div class="text-h6" id="info_class">
                      CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                    </div>
                  </div>
                </v-list-item-content>
                <v-list-item-content
                  v-else-if="selectedFeature.PARAM_PH_CLASS === 'II'"
                >
                  <div class="reading-info-card class-II">
                    <div class="text-h4" id="info_reading">
                      {{ selectedFeature.PARAM_PH }}
                    </div>
                    <div class="text-h6" id="info_class">
                      CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                    </div>
                  </div>
                </v-list-item-content>
                <v-list-item-content
                  v-else-if="selectedFeature.PARAM_PH_CLASS === 'III'"
                >
                  <div class="reading-info-card class-III">
                    <div class="text-h4" id="info_reading">
                      {{ selectedFeature.PARAM_PH }}
                    </div>
                    <div class="text-h6" id="info_class">
                      CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                    </div>
                  </div>
                </v-list-item-content>
                <v-list-item-content
                  v-else-if="selectedFeature.PARAM_PH_CLASS === 'IV'"
                >
                  <div class="reading-info-cardclass-IV">
                    <div class="text-h4" id="info_reading">
                      {{ selectedFeature.PARAM_PH }}
                    </div>
                    <div class="text-h6" id="info_class">
                      CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                    </div>
                  </div>
                </v-list-item-content>
                <v-list-item-content
                  v-else-if="selectedFeature.PARAM_PH_CLASS === 'V'"
                >
                  <div class="reading-info-card class-V">
                    <div class="text-h4" id="info_reading">
                      {{ selectedFeature.PARAM_PH }}
                    </div>
                    <div class="text-h6" id="info_class">
                      CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                    </div>
                  </div>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <div class="reading-info-card class-NA">
                    <div class="text-h4" id="info_reading">
                      {{
                        selectedFeature.PARAM_PH == "-1"
                          ? "NA"
                          : selectedFeature.PARAM_PH
                      }}
                    </div>
                    <div class="text-h6" id="info_class">
                      CLASS
                      {{
                        selectedFeature.PARAM_PH_CLASS == "-1" ||
                        selectedFeature.PARAM_PH_CLASS == null
                          ? "NA"
                          : selectedFeature.PARAM_PH_CLASS
                      }}
                    </div>
                  </div>
                </v-list-item-content> -->
              </v-list-item>
            </v-card-text>
          </v-card-title>

          <template>
            <v-expansion-panels v-model="extend_panel" accordion>
              <v-expansion-panel>
                <v-expansion-panel-header>PARAMETER</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template class="dashboard_table">
                    <v-data-table item-key="name" class="elevation-1 mb-2 station-info-table" :items-per-page="-1"
                      :disable-pagination="true" :disable-filtering="true" :hide-default-footer="true"
                      :hide-default-header="true">
                      <template v-slot:header>
                        <thead class="headers-class white--text">
                          <tr style="background: #004892">
                            <th style="color: white"></th>
                            <th style="color: white">Parameter</th>
                            <th style="color: white">Value & Unit</th>
                            <th style="color: white">Sub Index</th>
                            <th style="color: white">Loading</th>
                          </tr>
                        </thead>
                      </template>

                      <template v-slot:body>
                        <tbody class="text-center">
                          <tr v-for="(
                              nestedItem, nestedKey
                            ) in selectedStationInfo.parameter" :key="nestedKey">
                            <template>
                              <td v-if="nestedKey < 2 || nestedKey == 3" class="text-start">
                                <span :class="'dot-' + nestedItem.class"></span>
                              </td>
                              <td v-else class="text-start"></td>
                              <td class="text-start">{{ nestedItem.name }}</td>
                              <td v-if="
                                  nestedItem.value != null &&
                                  nestedItem.unit === 'm3/s'
                                " :class="
                                  nestedItem.value_class != ''
                                    ? nestedItem.value_class
                                    : 'text-start'
                                ">
                                {{ nestedItem.value }} m<sup>3</sup>/s
                              </td>
                              <td v-else-if="nestedItem.value != null" :class="
                                  nestedItem.value_class != ''
                                    ? nestedItem.value_class
                                    : 'text-start'
                                ">
                                {{ nestedItem.value + " " + nestedItem.unit }}
                              </td>
                              <td v-else class="text-start">NA</td>
                              <td v-if="nestedKey < 3 && nestedItem.si != null" class="text-start">
                                {{ nestedItem.si }}
                              </td>
                              <td v-else-if="nestedKey < 3" class="text-start">
                                NA
                              </td>
                              <td v-else class="text-start"></td>
                              <td class="text-start">
                                {{
                                nestedItem.loading == null
                                ? "NA"
                                : nestedItem.loading
                                }}
                              </td>

                              <!-- <td class="text-start">  {{ nestedItem.si}} </td> -->
                            </template>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="station_id !== ''">
                <v-expansion-panel-header>STATION DETAILS</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list class="transparent pt-0">
                    <v-list-item dense>
                      <v-list-item-title>Station ID: </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ selectedFeature.STATION_ID_A }}
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-title>Station Location: </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ selectedFeature.LOCATION }}
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item dense>
                      <v-list-item-title>Longitude: </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ selectedFeature.LONGITUDE }}
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-title>Latitude: </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ selectedFeature.LATITUDE }}
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item dense>
                      <v-list-item-title>PIC (Contact): </v-list-item-title>

                      <v-list-item-subtitle v-if="selectedFeature.PIC_NAME != ''">
                        {{ selectedFeature.PIC_NAME }}
                        {{ " ( " + selectedFeature.PIC_PHONE }}
                        {{ selectedFeature.PIC_EMAIL != "" ? ", " : "" }}
                        {{ selectedFeature.PIC_EMAIL }} )
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-else> None </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item dense>
                      <v-list-item-title>CCTV Link: </v-list-item-title>

                      <v-list-item-subtitle link="selectedFeature.CCTV_LINK">
                        <a :href="selectedFeature.CCTV_LINK" target="_blank">{{ selectedFeature.STATION_ID }} CCTV
                          Link</a>
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item dense>
                      <v-list-item-title>Station Picture: </v-list-item-title>
                      <v-list-item-subtitle v-if="
                          selectedFeature.STATION_PICTURE == '' &&
                          selectedFeature.DEPLOYMENT_PICTURE == '' &&
                          selectedFeature.UPSTREAM_PICTURE == '' &&
                          selectedFeature.DOWNSTREAM_PICTURE == ''
                        ">
                        None</v-list-item-subtitle>
                    </v-list-item>
                    <!-- DISABLE BECAUSE NO STATION INFO DEKAT API ------------- KENA UPDATE FARA -->
                    <v-list-item>
                      <v-row>
                        <v-col class="d-flex child-flex" cols="6" v-if="selectedFeature.STATION_PICTURE !== ''">
                          <v-img :src="
                              selectedFeature.STATION_PICTURE.replace(
                                'sarawakdir',
                                'publicdir'
                              )
                            " aspect-ratio="1" class="grey lighten-2">
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col class="d-flex child-flex" cols="6" v-if="selectedFeature.DEPLOYMENT_PICTURE !== ''">
                          <v-img :src="
                              selectedFeature.DEPLOYMENT_PICTURE.replace(
                                'sarawakdir',
                                'publicdir'
                              )
                            " aspect-ratio="1" class="grey lighten-2">
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col class="d-flex child-flex" cols="6" v-if="selectedFeature.UPSTREAM_PICTURE !== ''">
                          <v-img :src="
                              selectedFeature.UPSTREAM_PICTURE.replace(
                                'sarawakdir',
                                'publicdir'
                              )
                            " aspect-ratio="1" class="grey lighten-2">
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col class="d-flex child-flex" cols="6" v-if="selectedFeature.DOWNSTREAM_PICTURE !== ''">
                          <v-img :src="
                              selectedFeature.DOWNSTREAM_PICTURE.replace(
                                'sarawakdir',
                                'publicdir'
                              )
                            " aspect-ratio="1" class="grey lighten-2">
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-list-item>

                    <!-- -------------------------------------------------------------------- -->
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  EQUIPMENT DETAILS
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list v-for="(nestedItem, nestedKey) in equipmentDetail" :key="nestedKey">
                    <v-list-item class="py-2" dense>
                      <v-list-item-title>Brand: </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ nestedItem.BRAND }}
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item class="py-2" dense>
                      <v-list-item-title>Model: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ nestedItem.MODEL }}
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-title>Parameter: </v-list-item-title>

                      <v-list-item-subtitle v-html="nestedItem.PARAM_DEVICE_RANGE">
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="py-2" v-if="nestedItem.PICTURE != null" dense>
                      <v-list-item-title>Picture: </v-list-item-title>

                      <v-list-item-subtitle>
                        <v-img :src="
                            nestedItem.PICTURE.replace(
                              'sarawakdir',
                              'publicdir'
                            )
                          " width="100px" class="grey lighten-2">
                        </v-img>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <br />
                    <v-divider v-if="nestedKey < equipmentDetail.length - 1"></v-divider>
                  </v-list>

                  <v-divider :thickness="5"></v-divider>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  WATER TREATMENT PLANT INFORMATION
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <v-list v-for="(nestedItem, nestedKey) in waterIntakeDetail" :key="nestedKey">
                    <v-list-item class="py-2" dense>
                      <v-list-item-title>Water Treatment Plant: </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ nestedItem.WATER_INTAKE_NAME }}
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item class="py-1" dense>
                      <v-list-item-title>WTP Capacity: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ nestedItem.WTP_CAPACITY }}
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item class="py-1" dense>
                      <v-list-item-title>WTP Production Rate: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ nestedItem.WTP_PRODUCTION }}
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item class="py-1" dense>
                      <v-list-item-title>Coordinate: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ nestedItem.LATITUDE }}, {{ nestedItem.LONGITUDE }}
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item class="py-1" dense>
                      <v-list-item-title>Distance From Station: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ nestedItem.DISTANCE_FROM_STATION }}
                      </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item class="py-1" dense>
                      <v-list-item-title>Water Operator: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ nestedItem.WATER_OPERATOR }}
                      </v-list-item-subtitle>
                    </v-list-item>


                    <v-divider :thickness="5"></v-divider>
                  </v-list>

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
  import "ol/ol.css";

  import Map from "ol/Map";
  import View from "ol/View";
  import { defaults as defaultControls, ScaleLine } from "ol/control";
  import {
    Tile as TileLayer,
    Vector as VectorLayer,
    Group as GroupLayer,
  } from "ol/layer";
  import { OSM, Vector as VectorSource } from "ol/source";
  import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
  import Feature from "ol/Feature";
  import { fromLonLat } from "ol/proj";
  import { Point } from "ol/geom";
  import Overlay from "ol/Overlay";
  import { getBottomLeft, getTopRight } from "ol/extent.js";
  import { bus } from "@/main";

  // Axios for fetching data
  import axios from "axios";

  export default {
    data: () => ({

      // General
      desktopView: true,

      // Map Declaration
      map: null,
      view: null,
      extent: [
        102.66630587561784, 1.2743781878165312, 104.1082793997077,
        2.5980825900567535,
      ],
      baseMapLayer: {
        topographicMap: new TileLayer({
          title: "Topographic Map",
          source: new OSM({
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        imageryMap: new TileLayer({
          title: "Imagery Map",
          source: new OSM({
            // "url" : 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            // "url": 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        streetMap: new TileLayer({
          title: "OSM",
          source: new OSM({
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        googleStreetMap: new TileLayer({
          title: "Google Street",
          source: new OSM({
            url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
            // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
            crossOrigin: "anonymous",
          }),
          visible: true,
          type: "base",
        }),
        googleSateliteMap: new TileLayer({
          title: "OSM",
          source: new OSM({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
      },
      pointStyle: {
        default: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#3381ff",
            }),
            stroke: new Stroke({
              color: "#0242ab",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),
        selected: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#00ffff",
            }),
            stroke: new Stroke({
              color: "#003300",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),
      },

      mapButton: false,
      infoButton: false,
      displaySearch: false,
      poiState: false,
      listMapViews: [
        {
          src: require("@/assets/mapviewer/googlestreetmap.jpg"),
          name: "Google Street Map",
          layer: "googleStreetMap",
        },
        {
          src: require("@/assets/mapviewer/googlesatelitemap.jpg"),
          name: "Google Satellite Map",
          layer: "googleSateliteMap",
        },
        {
          src: require("@/assets/mapviewer/topo_map.jpg"),
          name: "Topographic Map",
          layer: "topographicMap",
        },
        {
          src: require("@/assets/mapviewer/imagery_map.jpeg"),
          name: "Imagery Map",
          layer: "imageryMap",
        },
        {
          src: require("@/assets/mapviewer/streetsmap.jpg"),
          name: "Street Map",
          layer: "streetMap",
        },
      ],

      //Table Data

      headers2: [
        {
          text: "Parameters",
          align: "start",
          sortable: false,
          value: "parameter",
          class: "fixed-th",
        },
      ],

      listParamDisplayed: [
        "pH",
        "TSS",
        "DO_SAT",
        "DO_CON",
        "TEMPERATURE",
        "TDS",
        "TURBIDITY",
        "CONDUCTIVITY",
        "SALINITY",
        "NH4",
        "NO3",
        "OIL_IN_WATER",
        "PAH_HYDROCARBON",
        "WATER_LEVEL",
        "VELOCITY",
        "FLOWRATE",
        "NH3",
        "NH3N",
        "TOTAL_AMMONIA",
        "SOLAR_BATTERY",
      ],

      listParamDisplayed_sgJohor: [
        "pH",
        "TSS",
        "DO_SAT",
        "DO_CON",
        "TEMPERATURE",
        "TDS",
        "TURBIDITY",
        "CONDUCTIVITY",
        "SALINITY",
        "NH4",
        "OIL_IN_WATER",
        "PAH_HYDROCARBON",
        "WATER_LEVEL",
        "VELOCITY",
        "FLOWRATE",
        "NH3N",
        "SOLAR_BATTERY",
      ],


      loadingParameters: [
        "DO_CON",
        "TSS",
        "TDS",
        "NH4",
        "NO3",
        "OIL_IN_WATER",
        "PAH_HYDROCARBON",
      ],

      national_water_quality_standard: [ //based on listParamDisplayed

        "6.00-9.00 ",
        "50.0 mg/L",
        "-",
        "-",
        "-",
        "-",
        "50.0 NTU",
        "-",
        "-",
        "-",
        "7.00 mg/L",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
        "0.3 mg/L",
        "-",
        "-",

      ],

      recommended_raw_water_quality: [
        "5.50–9.00",
        "-",
        "-",
        "-",
        "-",
        "1500.00 mg/L",
        "1000.0 NTU",
        "-",
        "-",
        "-",
        "10.00 mg/L",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
        "1.5 mg/L",
        "-",
        "-",
      ],

      paramContentDetail: [],
      stationDetails: [],
      tableContents: [],

      station_status: [
        {
          text: "Active",
          img: require("@/assets/mapviewer/new_icon/station_icon_green_3.svg"),
        },
        {
          text: "Inactive",
          img: require("@/assets/mapviewer/new_icon/station_icon_grey.svg"),
        },
        {
          text: "Transmission Delay",
          img: require("@/assets/mapviewer/new_icon/station_icon_orange_1.svg"),
        },
      ],

      card_station_name: "111",
      selection: "111",
      panel: [],
      displayOne: true,
      stationInfoList: [],
      selectedStationInfo: {},
      selectedFeature: {},
      displayInformation: false,
      stationPicture: [],
      station_id: "",
      extend_panel: 0,

      //exceedance Data

      exceedanceData: "",
      equipmentDetail: "",

      //expand Dashboard

      expandButton: true,
      shrinkButton: false,
      role: 0,
    }),
    methods: {
      initiateMap() {
        var source = new VectorSource();
        var vector = new VectorLayer({
          source: source,
        });

        var baseMap = new GroupLayer({
          layers: [
            this.baseMapLayer.topographicMap,
            this.baseMapLayer.imageryMap,
            this.baseMapLayer.streetMap,
            this.baseMapLayer.googleStreetMap,
            this.baseMapLayer.googleSateliteMap,
          ],
        });

        this.map = new Map({
          controls: defaultControls({
            zoom: false,
          }),
          // .extend([
          //     new ScaleLine({
          //         units: 'metric',
          //     }),
          // ]),
          target: "map",
          layers: [baseMap],
          view: new View({
            projection: "EPSG:4326",
            zoom: 1,
          }),
        });

        this.view = this.map.getView();
        this.view.fit(this.extent);
        this.map.on("moveend", this.onMoveEnd);

        this.onClickMap();
      },

      home() {
        this.displayInformation = false;
        this.expandButton = true;
        this.infoButton = false;
        this.mapButton = false;
        this.view = this.map.getView();
        this.view.fit(this.extent, {
          duration: 500,
        });
      },

      zoomStation(longitude, latitude) {
        this.map.setView(
          new View({
            projection: "EPSG:4326", //or any projection you are using
            center: [longitude, latitude], //zoom to the center of your feature
            zoom: 15, //here you define the levelof zoom
          })
        );
      },

      changeBasemap(layer) {
        // console.log("layer", layer);

        for (let i = 0; i < this.listMapViews.length; i++) {
          let layerName = this.listMapViews[i].layer;
          // console.log(layer,layerName);
          if (layer == layerName) this.baseMapLayer[layerName].setVisible(true);
          else this.baseMapLayer[layerName].setVisible(false);
        }
      },

      displayPOI() {
        this.poiState = !this.poiState;

        let that = this;

        let mapLayer = this.map.getLayers();

        for (let i in this.map.getLayers().getArray()) {
          let el = mapLayer.getArray()[i];
          if (el.get("name") === "Point Of Interest")
            el.setVisible(that.poiState);
        }
      },

      getPOIjson() {
        axios
          .get(this.globalUrl + "/bakaj/interest", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.pointOfInterestDetails = response.data;
            this.addPOIMarker();
          })
          .catch((error) => {
            console.log(error);
          });
      },

      addPOIMarker() {
        for (let i = 0; i < this.pointOfInterestDetails.length; i++) {
          var marker = new Feature({
            geometry: new Point([
              this.pointOfInterestDetails[i].LONGITUDE,
              this.pointOfInterestDetails[i].LATITUDE,
            ]),
            description: this.pointOfInterestDetails[i],
            name: "Point Of Interest",
          });
          var vectorSource = new VectorSource({
            features: [marker],
          });

          var POIMarker = new VectorLayer({
            name: "Point Of Interest",
            source: vectorSource,
            style: this.pointStyle.selected,
            visible: false,
          });
          this.map.addLayer(POIMarker);
        }

        // this.getManualJson();
      },

      onClickMap() {
        let that = this;

        this.map.on("click", function (evt) {
          // console.log("map", that.map.getView().getCenter())
          // let yeyeye = that.map.getView().getCenter()

          let feature = this.forEachFeatureAtPixel(
            evt.pixel,
            (feature) => feature
          );

          // console.log("feature = ", feature);
          if (feature !== undefined) {
            that.selectedFeature = feature.get("description");
            that.displayOne = false;
            that.extend_panel = 0;
            // let stationtype = feature.get("name");

            // console.log("staa", stationtype); cv

            // if( stationtype == "Point Of Interest"){
            //   that.displayPOITable = true;
            //   console.log("hehhehe");
            //   that.zoomStation(that.selectedFeature.longitude,that.selectedFeature.latitude);
            // }
            // else {

            if (feature.get("name") == "Point Of Interest")
              that.displayPOITable = true;
            else {
              that.displayInformation = true;
              that.expandButton = false;
            }

            that.station_id = that.selectedFeature.STATION_ID_A;

            // console.log(that);
            that.getSelectedStationInfo(that.selectedFeature.STATION_ID_A);
            that.getEquipmentDetail(that.selectedFeature.STATION_ID_A);
            that.getWaterIntakeDetail(that.selectedFeature.STATION_ID_A);

            that.zoomStation(
              that.selectedFeature.LONGITUDE,
              that.selectedFeature.LATITUDE
            );

            // }
          }
        });
      },

      getColor(calories) {
        if (calories > 400) return "red";
        else if (calories > 200) return "orange";
        else return "green";
      },

      getStationInfo() {
          axios.get(this.globalUrl + "bakaj/reading", {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
          })
          .then((response) => {

            let data = response.data;

            if( this.role == 5){
              for (let i in data){
                if( data[i].STATION_ID_A == "WQMS02" || data[i].STATION_ID_A == "WQMS03" || data[i].STATION_ID_A == "WQMS04"){
                  console.log("hehe", this.$store.getters.user.role);
                  this.stationDetails.push(data[i]);
                }      
              } 
            }
            else
            this.stationDetails = data;



            this.addMapMarker();

            // console.log(this.stationDetails);

            this.genTableHeader();
            this.getExceedanceValue();

            // let stationData = response.data;
            // this.stationList = [];
            // for(let i = 0; i < stationData.length; i++){
            //   this.stationList.push(stationData[i].stationid_a);

            // for(let j = 0; j < this.headers.length; j++){
            //   if(this.headers[j].value == stationData[i].stationid_a){
            //     this.headers[j].name = stationData[i].LOCATION;
            //     this.headers[j]['last_date'] = stationData[i].DATETIME;
            //   }
            // }
          })
          .catch((error) => {
            console.log(error);
          });
      },

      getExceedanceValue() {
        axios
          .get(this.globalUrl + "bakaj/exceedanceval", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.exceedanceData = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },

      getWaterIntakeDetail(station_id) {

        axios
          .get(
            this.globalUrl + "/bakaj/waterintake?station=" + station_id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
            }
          )
          .then((response) => {
            this.waterIntakeDetail = response.data;

            // console.log("wid", this.waterIntakeDetail);

          })
          .catch((error) => {
            console.log(error);
          });

      },

      getEquipmentDetail(station_id) {
        axios
          .get(this.globalUrl + "bakaj/equipmentdetail?stationid=" + station_id, {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.equipmentDetail = response.data;
            // this.equipmentDetail = this.equipmentDetail.replace("||", "aaaa")

            this.equipmentDetail = JSON.parse(
              JSON.stringify(this.equipmentDetail).replaceAll("||", " <br />")
            );
            console.log("equip", this.equipmentDetail);
          })
          .catch((error) => {
            console.log(error);
          });
      },

      getParamInfo() {
          axios.get(this.globalUrl + "bakaj/params", {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
          })
          .then((response) => {
            this.paramDetails = response.data;

            // console.log(this.paramDetails);

            this.genParamList();
          })
          .catch((error) => {
            console.log(error);
          });
      },

      genTableHeader() {
        // console.log('fuhh',this.stationDetails);

        for (let i = 0; i < this.stationDetails.length; i++) {
          let dt = "";
          let t = "";
          let tempDatetime;

          if (this.stationDetails[i]["DATETIME_A"].includes("T")) {
            dt = this.stationDetails[i]["DATETIME_A"].split("T")[0];
            t = this.stationDetails[i]["DATETIME_A"].split("T")[1];

            let dt2 =
              dt.split("-")[2] + "/" + dt.split("-")[1] + "/" + dt.split("-")[0];
            tempDatetime = dt2 + " " + t;
          }

          let headerName = {
            text:
              this.stationDetails[i].STATION_ID_A +
              ", " +
              this.stationDetails[i].STATE_NAME +
              " " +
              tempDatetime,
            station: this.stationDetails[i].STATION_ID_A,
            location: this.stationDetails[i].LOCATION,
            datetime: this.stationDetails[i].DATETIME_A,
          };

          this.headers2.push(headerName);
        }

        this.getParamInfo();
      },

      genParamList() {

        let paramToDisplay = this.role == 5 ? this.listParamDisplayed_sgJohor : this.listParamDisplayed ;

        for (let j = 0; j < paramToDisplay.length; j++) {
          for (let i = 0; i < this.paramDetails.length; i++) {
            if (paramToDisplay[j] == this.paramDetails[i].reading) {
              // console.log("listParamDisplayed", paramToDisplay[j], this.paramDetails[i].reading)

              Object.keys(this.paramDetails[i]).forEach((key) => {
                if (this.paramDetails[i][key] === null) {
                  this.paramDetails[i][key] = "";
                }
              });

              let paramContent = {
                name: paramToDisplay[j],
                fullName: this.paramDetails[i].fullname,
                unit: this.paramDetails[i].unit,
              };

              this.paramContentDetail.push(paramContent);
            }
          }
        }

        // console.log("syationdeta", this.stationDetails);

        this.genTable();
      },

      genTable() {
        //  console.log("tableContent", this.stationDetails)

        this.getStationInfoList(); // GET CONTENT FOR STATION INFO

        console.log(this.paramContentDetail);

        for (let i = 0; i < this.paramContentDetail.length; i++) {
          let parameter = this.paramContentDetail[i].name;
          let tableContent = {
            parameter: this.paramContentDetail[i].fullName,
            class: "datatable-class",
            //   si: this.stationDetails[i][
            //   "PARAM_SI_" + this.paramContentDetail[i]
            // ],
          };

          // let stationDetailsParse =  JSON.stringify(this.stationDetails);

          for (let j = 0; j < this.stationDetails.length; j++) {
            //REPLACE NULL VALUES TO Na

            Object.keys(this.stationDetails[j]).forEach((key) => {
              // console.log("hehehe", this.stationDetails[j][key]);

              if (this.stationDetails[j][key] === null) {
                this.stationDetails[j][key] = "NA";
              }
            });

            let tempValue = this.stationDetails[j][parameter];

            // console.log("aaaaaaaaaa",parameter,tempValue);

            // if (tempValue == "NA") {
            //   tempValue = this.stationDetails[j][parameter + "_FLAG"];

            // }

            // let tempValue = stationDetailsParse

            // console.log("temp", tempValue)
            // tempValue = tempValue.replace(null, "NA");
            if (this.paramContentDetail[i].name == "WQI") {
              let tempObj = {
                value: this.stationDetails[j]["PARAM_PH"],
                class: this.stationDetails[j]["PARAM_PH_CLASS"],
              };

              tableContent[this.stationDetails[j].STATION_ID_A] = tempObj;
            } else {
              if (tempValue !== "NA"){

                // Min Max Controll
                if(this.stationDetails[j][parameter + "_MAX_WAR"] != undefined || this.stationDetails[j][parameter + "_MAX_WAR"] != "NA"){
                  if (this.stationDetails[j][parameter] > this.stationDetails[j][parameter + "_MAX_WAR"]){
                    if ( this.paramContentDetail[i].name == "NH3" || this.paramContentDetail[i].name == "NH4" || this.paramContentDetail[i].name == "NH3N" || this.paramContentDetail[i].name == "TOTAL_AMMONIA" || this.paramContentDetail[i].name == "PAH_HYDROCARBON" || this.paramContentDetail[i].name == "OIL_IN_WATER" ){
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                      "<span class='alert-text-animation' >" + 
                        Number(tempValue).toFixed(4) + " " + this.paramContentDetail[i].unit;
                      + "</span>";
                    } else {
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                      "<span class='alert-text-animation' >" + 
                        Number(tempValue).toFixed(1) + " " + this.paramContentDetail[i].unit;
                      + "</span>";
                    }
                  }
                  else{
                    if ( this.paramContentDetail[i].name == "TSS" || this.paramContentDetail[i].name == "TURBIDITY" ){
                      tableContent[this.stationDetails[j].STATION_ID_A] = 
                        Number(tempValue).toFixed(1) + " " + this.paramContentDetail[i].unit;
                    }
                    else if ( this.paramContentDetail[i].name == "NH3" || this.paramContentDetail[i].name == "NH4" || this.paramContentDetail[i].name == "NH3N" || this.paramContentDetail[i].name == "TOTAL_AMMONIA" || this.paramContentDetail[i].name == "PAH_HYDROCARBON" || this.paramContentDetail[i].name == "OIL_IN_WATER" ){
                      tableContent[this.stationDetails[j].STATION_ID_A] = 
                        Number(tempValue).toFixed(4) + " " + this.paramContentDetail[i].unit;
                    }
                    else if (this.paramContentDetail[i].name == "FLOWRATE"){
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                        Number(tempValue).toFixed(2) + " ";
                    }
                    else if (this.paramContentDetail[i].name == "NH3" || this.paramContentDetail[i].name == "PAH_HYDROCARBON" || this.paramContentDetail[i].name == "OIL_IN_WATER" ){
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                      Number(tempValue).toFixed(4) + " " + this.paramContentDetail[i].unit;
                    }
                    else{
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                        Number(tempValue).toFixed(2) + " " + this.paramContentDetail[i].unit;
                    }
                  }
                }
                else if(this.stationDetails[j][parameter + "_MIN_WAR"] != undefined || this.stationDetails[j][parameter + "_MIN_WAR"] != "NA"){
                  if (this.stationDetails[j][parameter] < this.stationDetails[j][parameter + "_MIN_WAR"]){
                    if ( this.paramContentDetail[i].name == "NH3" || this.paramContentDetail[i].name == "NH4" || this.paramContentDetail[i].name == "NH3N" || this.paramContentDetail[i].name == "TOTAL_AMMONIA" || this.paramContentDetail[i].name == "PAH_HYDROCARBON" || this.paramContentDetail[i].name == "OIL_IN_WATER" ){
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                      "<span class='alert-text-animation' >" + 
                      Number(tempValue).toFixed(4) + " " + this.paramContentDetail[i].unit;
                      + "</span>";
                    } else {
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                      "<span class='alert-text-animation' >" + 
                      Number(tempValue).toFixed(1) + " " + this.paramContentDetail[i].unit;
                      + "</span>";
                    }
                   
                  }
                  else{
                    if ( this.paramContentDetail[i].name == "TSS" || this.paramContentDetail[i].name == "TURBIDITY" ){
                      tableContent[this.stationDetails[j].STATION_ID_A] = 
                        Number(tempValue).toFixed(1) + " " + this.paramContentDetail[i].unit;
                    }
                    else if (this.paramContentDetail[i].name == "FLOWRATE"){
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                        Number(tempValue).toFixed(2) + " ";
                    }
                    else if (this.paramContentDetail[i].name == "NH3" || this.paramContentDetail[i].name == "NH4" || this.paramContentDetail[i].name == "NH3N" || this.paramContentDetail[i].name == "TOTAL_AMMONIA" || this.paramContentDetail[i].name == "PAH_HYDROCARBON" || this.paramContentDetail[i].name == "OIL_IN_WATER"){
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                      Number(tempValue).toFixed(4) + " " + this.paramContentDetail[i].unit;
                    }
                    else{
                      tableContent[this.stationDetails[j].STATION_ID_A] =
                        Number(tempValue).toFixed(2) + " " + this.paramContentDetail[i].unit;
                    }
                  }
                }
                else{
                  if ( this.paramContentDetail[i].name == "TSS" || this.paramContentDetail[i].name == "TURBIDITY" ){
                    tableContent[this.stationDetails[j].STATION_ID_A] = 
                      Number(tempValue).toFixed(1) + " " + this.paramContentDetail[i].unit;
                  }
                  else if ( this.paramContentDetail[i].name == "NH3" || this.paramContentDetail[i].name == "NH4" || this.paramContentDetail[i].name == "NH3N" || this.paramContentDetail[i].name == "TOTAL_AMMONIA" || this.paramContentDetail[i].name == "PAH_HYDROCARBON" || this.paramContentDetail[i].name == "OIL_IN_WATER"){
                    tableContent[this.stationDetails[j].STATION_ID_A] = 
                      Number(tempValue).toFixed(4) + " " + this.paramContentDetail[i].unit;
                  }
                  else if (this.paramContentDetail[i].name == "FLOWRATE"){
                    tableContent[this.stationDetails[j].STATION_ID_A] =
                      Number(tempValue).toFixed(2) + " ";
                  }
                  else if (this.paramContentDetail[i].name == "NH3" || this.paramContentDetail[i].name == "NH4" || this.paramContentDetail[i].name == "NH3N" || this.paramContentDetail[i].name == "TOTAL_AMMONIA" || this.paramContentDetail[i].name == "PAH_HYDROCARBON" || this.paramContentDetail[i].name == "OIL_IN_WATER"){
                    tableContent[this.stationDetails[j].STATION_ID_A] =
                    Number(tempValue).toFixed(4) + " " + this.paramContentDetail[i].unit;
                  }
                  else{
                    tableContent[this.stationDetails[j].STATION_ID_A] =
                      Number(tempValue).toFixed(2) + " " + this.paramContentDetail[i].unit;
                  }
                }

                
                  

              }
              else {
                let tempParameter;
                if (parameter == "TEMPERATURE") tempParameter = "TEMP";
                else if (parameter == "DO_SAT") tempParameter = "DOSAT";
                else if (parameter == "DO_CON") tempParameter = "DOCON";
                else tempParameter = parameter;

                if (this.stationDetails[j][tempParameter + "_FLAG"] != "NA"){
                  tableContent[this.stationDetails[j].STATION_ID_A] =
                    "<span class='alert-text' >" + this.stationDetails[j][tempParameter + "_FLAG"] + "</span>";
                }
                else{

                  // if (this.stationDetails[j][parameter] < 30){
                  //   tableContent[this.stationDetails[j].STATION_ID_A] =
                  //     "<span style = 'color:red' >" + this.stationDetails[j][tempParameter + "_FLAG"] + "</span>";
                  // }

                  tableContent[this.stationDetails[j].STATION_ID_A] = tempValue;
                  
                }
                  
              }
            }
          }

          this.tableContents.push(tableContent);
          // console.log(this.tableContents);
        }

        console.log("stationinfo!!!",this.stationDetails);
        console.log("tbody!!!!!!",this.tableContents);
      },

      setRowStyle(item) {
        /* Here you can also do some validation
            in case you want to apply different css 
            classes depending on item attributes 
            values */
        return "style-1";
      },

      consoleSumtin() {
        // console.log(JSON.stringify(this.tableContents))
        this.addMapMarker();

        // console.log((this.tableContents));

        this.getStationInfoList();
      },

      getArray(item) {
        delete item.parameter;
        delete item.class;

        return item;
      },

      checkRole() {
        
        if(this.role == 5){

          let dashboard = document.getElementById("dashboard");
          let map = document.getElementById("map");

          map.style.width = "60%";
          map.style.height = "100%";
          dashboard.style.left = "60%";
          dashboard.style.width = "40%";
          this.map.updateSize();
        }
        else {
          let dashboard = document.getElementById("dashboard");
          let map = document.getElementById("map");

          map.style.width = "50%";
          map.style.height = "100%";
          dashboard.style.left = "50%";
          dashboard.style.width = "50%";
          this.map.updateSize();

        }
      },

      expandDashboard() {
        let dashboard = document.getElementById("dashboard");
        let map = document.getElementById("map");

        dashboard.style.width = "100%";
        dashboard.style.left = "0%";
        map.style.width = "0%";
        this.expandButton = false;
        this.shrinkButton = true;
      },

      shrinkDashboard() {
        let dashboard = document.getElementById("dashboard");
        let map = document.getElementById("map");

        map.style.width = "50%";
        map.style.height = "100%";
        dashboard.style.left = "50%";
        dashboard.style.width = "50%";

        this.expandButton = true;
        this.shrinkButton = false;
        this.extend_panel = 0;

        this.addMapOverlay();
        this.checkRole();
      },

      setUiCurrentUi(){

        let dashboard = document.getElementById("dashboard");
        let map = document.getElementById("map");
        
        if(this.$vuetify.breakpoint.lgAndUp == true){
          this.desktopView = true;
        }
        else{
          this.desktopView = false;
        }

      },

      addMapMarker() {
        for (let i = 0; i < this.stationDetails.length; i++) {
          var marker = new Feature({
            geometry: new Point([
              this.stationDetails[i].LONGITUDE,
              this.stationDetails[i].LATITUDE,
            ]),
            description: this.stationDetails[i],
          });
          var vectorSource = new VectorSource({
            features: [marker],
          });

          var markerVectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
              image: new Icon({
                crossOrigin: "anonymous",
                src: this.stationDetails[i].GREEN == "TRUE" ? this.station_status[0].img : this.stationDetails[i].GREY == "TRUE" ? this.station_status[1].img : this.stationDetails[i].YELLOW == "TRUE" ? this.station_status[2].img : "", scale: 1.5,
              }),
            }),
          });
          this.map.addLayer(markerVectorLayer);
        }
        this.addMapOverlay();
      },

      addMapOverlay() {
        for (let i = 0; i < this.stationDetails.length; i++) {
          // console.log("wuwuwu", this.stationDetails[i].STATION_ID_A);

          // let elementID = "reading_" + this.stationDetails[i].STATION_ID_A;

          // let div = document.createElement("DIV");
          // div.setAttribute("id", elementID);

          // div.setAttribute("class", "reading-div");

          // console.log("here",this.stationDetails[i].PARAM_PH_CLASS);

          // // div.innerHTML = this.stationDetails[i].PARAM_PH;
          // if (this.stationDetails[i].PARAM_PH_CLASS == "I")
          //   div.setAttribute("class", "reading-div overlayclass class-I");
          // else if (this.stationDetails[i].PARAM_PH_CLASS == "II")
          //   div.setAttribute("class", "reading-div overlayclass class-II");
          // else if (this.stationDetails[i].PARAM_PH_CLASS == "III")
          //   div.setAttribute("class", "reading-div overlayclass class-III");
          // else if (this.stationDetails[i].PARAM_PH_CLASS == "IV")
          //   div.setAttribute("class", "reading-div overlayclass class-IV");
          // else if (this.stationDetails[i].PARAM_PH_CLASS == "V")
          //   div.setAttribute("class", "reading-div overlayclass class-V");
          // else if (this.stationDetails[i].PARAM_PH_CLASS == null)
          //   div.setAttribute("class", "reading-div overlayclass class-NA");

          // div.innerHTML =
          //   this.stationDetails[i].PARAM_PH_CLASS == null
          //     ? "NA"
          //     : this.stationDetails[i].PARAM_PH_CLASS;

          // document.getElementById("map").appendChild(div);

          // let overlay = new Overlay({
          //   element: document.getElementById(elementID),
          //   id: this.stationDetails[i].STATION_ID_A,
          //   positioning: "top-center",
          //   offset: [-18, -52],
          // });

          // overlay.setPosition([
          //   this.stationDetails[i].LONGITUDE,
          //   this.stationDetails[i].LATITUDE,
          // ]);

          // this.map.addOverlay(overlay);

          let labelStationID = "label_" + this.stationDetails[i].STATION_ID_A;

          let divLabel = document.createElement("DIV");

          divLabel.setAttribute("id", labelStationID);

          let position = "";

          if (this.stationDetails[i].STATION_ID_A == "WQMS03") {
            divLabel.setAttribute(
              "style",
              "height: auto;background-color: #fff !important; color: black; padding: 10px; position: relative; font-size: 10px !important; opacity: 70%; font-weight: bold;"
            );
            position = [10, -85];
          } else if (this.stationDetails[i].STATION_ID_A == "WQMS02") {
            divLabel.setAttribute(
              "style",
              "height: auto;background-color: #fff !important; color: black; padding: 10px; position: relative; font-size: 10px !important; opacity: 70%; font-weight: bold;"
            );
            position = [65, -35];
          } else {
            divLabel.setAttribute(
              "style",
              "height: auto;background-color: #fff !important; color: black; padding: 10px; position: relative; font-size: 10px !important; opacity: 70%; font-weight: bold;"
            );
            position = [-64, -35];
          }

          divLabel.innerHTML = this.stationDetails[i].STATION_ID_A + "<br>" + this.stationDetails[i].LOCATION;
          document.getElementById("map").appendChild(divLabel);

          let overlayLabel = new Overlay({
            element: document.getElementById(labelStationID),
            id: "label__" + this.stationDetails[i].STATION_ID_A,
            positioning: "top-center",
            offset: position,
          });

          overlayLabel.setPosition([
            this.stationDetails[i].LONGITUDE,
            this.stationDetails[i].LATITUDE,
          ]);

          this.map.addOverlay(overlayLabel);
        }
      },

      display(id, value) {
        console.log(id, value);
      },

      wrapLon(value) {
        const worlds = Math.floor((value + 180) / 360);
        return value - worlds * 360;
      },

      onMoveEnd(evt) {
        // console.log(this.map);
        // console.log(this.view);

        const map = evt.map;
        // const extent = this.map.getView().calculateExtent(map.getSize());
        // const bottomLeft = fromLonLat(getBottomLeft(extent));
        // const topRight = fromLonLat(getTopRight(extent));
        // this.display('left', this.wrapLon(bottomLeft[0]));
        // this.display('bottom', bottomLeft[1]);
        // this.display('right', this.wrapLon(topRight[0]));
        // this.display('top', topRight[1]);
        // this.display("bottomLeft", fromLonLat(getBottomLeft(extent)))

        // // const extent2 =  this.map.zoomExtent(extent2)
        this.display("extent2", map.getView().calculateExtent());
      },

      displayStatInfo(statInfo) {
        this.getSelectedStationInfo(statInfo);
        this.displayInformation = true;
        this.displayOne = !this.displayOne;
        this.expandButton = false;
      },

      getStationInfoList() {
        let listParam = [];
        let keys;
        for (let i = 0; i < this.stationDetails.length; i++) {
          keys = Object.keys(this.stationDetails[i]);
        }

        //get all properties that include param string
        const allParamKeys = keys.filter((keys) => {
          return keys.includes("param");
        });

        //get all paramaters without sub index and class
        let paramWithoutSiAndClass = allParamKeys.filter((allParamKeys) => {
          return !allParamKeys.includes("si") && !allParamKeys.includes("class");
        });

        //get all parameters name only
        let parameters = allParamKeys.filter((paramWithoutSiAndClass) => {
          return ( !paramWithoutSiAndClass.includes("si") && !paramWithoutSiAndClass.includes("class") );
        });

        parameters.forEach(function (param, index) {
          // console.log("huww", parameters[index]);
          parameters[index] = parameters[index].replace("param_", "");
        });

        // console.log("paramDetails", JSON.stringify(this.paramDetails))
        // console.log("parameterd", parameters)
        // console.log("stationDetails", JSON.stringify(this.stationDetails))

        for (let i = 0; i < this.stationDetails.length; i++) {
          let paramList = [];

          // for(let k = 0; k < parameters.length ; k++){

          let paramObj = {};

          let paramToDisplay = this.role == 5 ? this.listParamDisplayed_sgJohor : this.listParamDisplayed ;

          for (let j = paramToDisplay.length - 1; j > -1; j--) {
            for (let m = 0; m < this.paramDetails.length; m++) {
              if (paramToDisplay[j] == this.paramDetails[m].reading) {
                let valueParam =
                  this.stationDetails[i][paramToDisplay[j]];

                if (valueParam != null) {
                  if (
                    paramToDisplay[j] == "TSS" ||
                    paramToDisplay[j] == "TURBIDITY"
                  ) {
                    valueParam = Number(valueParam).toFixed(1);
                  }
                  else if (
                    paramToDisplay[j] == "NH3" ||
                    paramToDisplay[j] == "PAH_HYDROCARBON" ||
                    paramToDisplay[j] == "OIL_IN_WATER" ||  paramToDisplay[j] == "NH4" ||  paramToDisplay[j] == "NH3N" || paramToDisplay[j] == "TOTAL_AMMONIA"
                  ) {
                    valueParam = Number(valueParam).toFixed(4);
                  } else valueParam = Number(valueParam).toFixed(2);
                }

                let tempParam;

                let tempParameter;
                if (paramToDisplay[j] == "TEMPERATURE")
                  tempParameter = "TEMP";
                else if (paramToDisplay[j] == "DO_SAT")
                  tempParameter = "DOSAT";
                else if (paramToDisplay[j] == "DO_CON")
                  tempParameter = "DOCON";
                else tempParameter = paramToDisplay[j];

                if (this.exceedanceData.length > 0) {
                  for (let k = 0; k < this.exceedanceData.length; k++) {
                    if (
                      this.stationDetails[i].STATION_ID_A ==
                      this.exceedanceData[k].STATION_ID
                    ) {
                      if (
                        this.exceedanceData[k][paramToDisplay[j]] != null
                      ) {
                        paramObj = {
                          name: this.paramDetails[m].fullname,
                          value: valueParam == null ? this.stationDetails[i][tempParameter + "_FLAG"] : valueParam,
                          value_class: valueParam == null ? "flag-color text-start" : "",
                          unit: valueParam == null ? "" : this.paramDetails[m].unit,
                          si: this.stationDetails[i][ "PARAM_SI_" + paramToDisplay[j].toUpperCase() ],
                          class: paramToDisplay[j] == "DO_CON" ? this.stationDetails[i]["PARAM_DO_SAT_CLASS"] : this.stationDetails[i][ "PARAM_" + paramToDisplay[j].toUpperCase() + "_CLASS" ],
                          loading: this.loadingParameters.includes( paramToDisplay[j] ) == true ? this.stationDetails[i][ paramToDisplay[j] + "_LOADING" ] : "",
                        };
                      } else {
                        paramObj = {
                          name: this.paramDetails[m].fullname,
                          value: valueParam == null ? this.stationDetails[i][tempParameter + "_FLAG"] : valueParam,
                          value_class: valueParam == null ? "flag-color text-start" : "",
                          unit: valueParam == null ? "" : this.paramDetails[m].unit,
                          si: this.stationDetails[i][ "PARAM_SI_" + paramToDisplay[j].toUpperCase() ],
                          class: paramToDisplay[j] == "DO_CON" ? this.stationDetails[i]["PARAM_DO_SAT_CLASS"] : this.stationDetails[i][ "PARAM_" + paramToDisplay[j].toUpperCase() + "_CLASS" ],
                          loading: this.loadingParameters.includes( paramToDisplay[j] ) == true ? this.stationDetails[i][ paramToDisplay[j] + "_LOADING" ] : "",
                        };
                      }
                    } else {
                      paramObj = {
                        name: this.paramDetails[m].fullname,
                        value: valueParam == null ? this.stationDetails[i][tempParameter + "_FLAG"] : valueParam,
                        value_class: valueParam == null ? "flag-color text-start" : "",
                        unit: valueParam == null ? "" : this.paramDetails[m].unit,
                        si: this.stationDetails[i][ "PARAM_SI_" + paramToDisplay[j].toUpperCase() ],
                        class: paramToDisplay[j] == "DO_CON" ? this.stationDetails[i]["PARAM_DO_SAT_CLASS"] : this.stationDetails[i][ "PARAM_" + paramToDisplay[j].toUpperCase() + "_CLASS" ],
                        loading: this.loadingParameters.includes( paramToDisplay[j] ) == true ? this.stationDetails[i][ paramToDisplay[j] + "_LOADING" ] : "",
                      };
                    }
                  }
                } else {
                  paramObj = {
                    name: this.paramDetails[m].fullname,
                    value: valueParam == null ? this.stationDetails[i][tempParameter + "_FLAG"] : valueParam,
                    value_class: valueParam == null ? "flag-color text-start" : "", unit: valueParam == null ? "" : this.paramDetails[m].unit,
                    si: this.stationDetails[i][ "PARAM_SI_" + paramToDisplay[j].toUpperCase() ],
                    class: paramToDisplay[j] == "DO_CON" ? this.stationDetails[i]["PARAM_DO_SAT_CLASS"] : this.stationDetails[i][ "PARAM_" + paramToDisplay[j].toUpperCase() + "_CLASS" ],
                    loading: this.loadingParameters.includes( paramToDisplay[j] ) == true ? this.stationDetails[i][ paramToDisplay[j] + "_LOADING" ] : "",
                  };
                }

                // console.log( "param_si_"+(paramToDisplay[j]).toLowerCase(),this.stationDetails[i]["param_si_"+(paramToDisplay[j]).toLowerCase()]);

                // console.log("load", this.stationDetails[i][(paramToDisplay[j]).toUpperCase() + "_LOADING"], paramToDisplay[j].toUpperCase() + "_LOADING" );

                if (parameters.includes(paramToDisplay[j].toLowerCase()))
                  paramList.push(paramObj);
                else paramList.unshift(paramObj);
              }
            }
          }

          let stationInfoObj = {
            stationId: this.stationDetails[i].STATION_ID_A,
            location: this.stationDetails[i].LOCATION,
            datetime: this.stationDetails[i].datetime_a,
            longitude: this.stationDetails[i].LONGITUDE,
            latitude: this.stationDetails[i].LATITUDE,
            station_picture: this.stationDetails[i].STATION_PICTURE,
            deployment_picture: this.stationDetails[i].DEPLOYMENT_PICTURE,
            upstream_picture: this.stationDetails[i].UPSTREAM_PICTURE,
            downstream_picture: this.stationDetails[i].DOWNSTREAM_PICTURE,
            parameter: paramList,
          };

          this.stationInfoList.push(stationInfoObj);
        }

        console.log("ssss", this.stationInfoList);
      },

      getSelectedStationInfo(statID) {
        this.stationPicture = [];

        console.log("stat", statID);

        for (let i = 0; i < this.stationInfoList.length; i++) {
          // console.log(this.stationInfoList[i].stationId, statID);
          if (this.stationInfoList[i].stationId == statID) {
            console.log("uwu");
            this.selectedStationInfo = this.stationInfoList[i];
          }
        }

        for (let i = 0; i < this.stationDetails.length; i++) {
          // console.log(this.stationInfoList[i].stationId, statID);
          if (this.stationDetails[i].STATION_ID_A == statID) {
            this.selectedFeature = this.stationDetails[i];
            this.zoomStation(
              this.selectedFeature.LONGITUDE,
              this.selectedFeature.LATITUDE
            );
          }
        }

        let keys = Object.keys(this.selectedStationInfo);
        //get all properties that include param string
        let arrParam = keys.filter((keys) => {
          return keys.includes("picture");
        });

        for (let i = 0; i < arrParam.length; i++) {
          let obj = {
            src: this.selectedStationInfo[arrParam[i]],
          };

          this.stationPicture.push(obj);
        }

        // console.log(this.selectedStationInfo);
      },

      checkAdmin() {
        this.thisuser = this.$store.getters.user.idUserAccess;
        bus.$emit("admincanuse", this.thisuser);
      },
    },

    mounted() {

      this.role = this.$store.getters.user.role;

      this.initiateMap();
      this.checkAdmin();
      this.getStationInfo();

      this.getPOIjson();

      this.setUiCurrentUi();

      this.checkRole();

      // window.onresize = function()
      // {
      //   setTimeout( function() { this.map.updateSize();}, 200);
      // }
    },
  };
</script>

<style scoped>
  .whole-page {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /* #map {
    width: 50vw;
    height: 100%;
  } */

  .map {
    width: 50vw;
    height: 100%;
  }

  .map_mobile {
    width: 100vw;
    height: 100%;
  }

  #dashboard {
    position: fixed;
    width: 50%;
    height: 100%;
    z-index: 3;
    left: 50%;
    background: whitesmoke;
  }

  #dashboard_mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: whitesmoke;
  }

  #stationInfo {
    position: absolute;
    width: 50%;
    height: 90% !important;
    z-index: 5;
    left: 53%;
    top: 10px;
  }

  .mapviewerBtn {
    position: absolute;
    left: 10px;
    z-index: 5;
  }

  /* table.v-table thead tr  {
  height: 100px !important;
  color: red !important;
} */

  .dashboard_table>.v-data-table__wrapper>table>.v-data-table-header>tr>th {
    color: #276173 !important;
  }

  table thead tr {
    background-image: linear-gradient(to top, #005cbb, #004892) !important;
  }

  .v-data-table tbody tr:nth-of-type(even) td {
    background: rgba(236, 237, 237) !important;
  }

  tbody tr:nth-of-type(odd) td {
    background-color: rgb(250, 250, 250);
  }

  #map::v-deep th {
    background-image: linear-gradient(to top, #005cbb, #004892) !important;
    color: white;
    position: sticky;
  }

  #map::v-deep .class-I {
    background-color: #2d7ef8 !important;
  }

  #map::v-deep .class-II {
    background-color: #7cde6b !important;
  }

  #map::v-deep .class-III {
    background-color: #ffdd03 !important;
  }

  #map::v-deep .class-IV {
    background-color: #ffa834 !important;
  }

  #map::v-deep .class-V {
    background-color: #ff7e71 !important;
  }

  #map::v-deep .class-NA {
    background-color: #cccccc !important;
  }

  #map::v-deep .level-stationID {
    background-color: #cccccc !important;
    text-align: center;
    /* width: 35px; */
  }

  #map::v-deep .dot-I {
    height: 15px;
    width: 15px;
    background-color: #2d7ef8;
    border-radius: 50%;
    display: inline-block;
  }

  #map::v-deep .dot-II {
    height: 15px;
    width: 15px;
    background-color: #7cde6b;
    border-radius: 50%;
    display: inline-block;
  }

  #map::v-deep .dot-III {
    height: 15px;
    width: 15px;
    background-color: #ffdd03;
    border-radius: 50%;
    display: inline-block;
  }

  #map::v-deep .dot-IV {
    height: 15px;
    width: 15px;
    background-color: #ffa834;
    border-radius: 50%;
    display: inline-block;
    animation: blinker 2s linear infinite;
  }

  #map::v-deep .dot-V {
    height: 15px;
    width: 15px;
    background-color: #ff3823;
    border-radius: 50%;
    display: inline-block;
    animation: blinker 2s linear infinite;
  }

  /* #map::v-deep .dot-NA {
  height: 15px;
  width: 15px;
  background-color: #cccccc;
  border-radius: 50%;
  display: inline-block;
} */

  #map::v-deep .box.arrow-right:after {
    content: " ";
    position: absolute;
    right: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid #fff;
    border-bottom: 15px solid transparent;
  }

  #map::v-deep .box.arrow-left:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid #fff;
    border-left: none;
    border-bottom: 15px solid transparent;
    display: block;
  }

  ::v-deep .box.arrow-bottom:after {
    content: " ";
    position: absolute;
    right: 30px;
    bottom: -15px;
    border-top: 15px solid #fff;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }

  ::v-deep .box.arrow-top:after {
    content: " ";
    position: absolute;
    right: 30px;
    bottom: -15px;
    border-top: 15px solid #fff;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }

  #map::v-deep .box {
    height: auto;
    background-color: #fff;
    color: black;
    padding: 10px;
    position: relative;
    font-size: 10px !important;
    opacity: 70%;
    font-weight: bold;
  }

  .station-info-card {
    position: absolute;
    z-index: 5;
    overflow: auto;
    -webkit-user-drag: element;
    width: 100%;
  }

  .headers_class>tr>th {
    color: #276173 !important;
  }

  .station-info-table>.v-data-table__wrapper {
    background: #a5a5a5 !important;
  }

  /* #map::v-deep th {
   overflow: hidden;
   background-color: black;
   position: fixed;
   top: 20;
   width: 100%;

} */

  .fixed {
    position: sticky !important;
    width: 5em;
    left: 0;
    top: auto;
    z-index: 2;
  }

  .fixed-th {
    position: sticky !important;
    width: 5em;
    left: 0;
    top: auto;
    z-index: 99 !important;
  }

  td:not(.fixed) {
    z-index: 0;
  }

  #map::v-deep th {
    z-index: 4;
  }

  #map::v-deep .td-class {
    min-width: 185px !important;
    height: 60px;
  }

  #map::v-deep .blinking {
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .expand-button {
    position: absolute;
    z-index: 3;
    left: 46.7%;
  }

  .expand-button-lg {
    position: absolute;
    z-index: 3;
    left: 45.9%;
  }

  .close-button {
    position: absolute;
    z-index: 99;
    right: 30px;
    top: 15px;
  }

  #map::v-deep .class-red {
    color: red !important;
    animation: blinker 2s linear infinite;
  }

  #map::v-deep .flag-color {
    color: red !important;
  }

  /* #map::v-deep canvas {
  width: 952px !important;
  height: 937px !important;
  left: 5% !important;

} */

  .td-header {
    background: rgb(0, 212, 255);
    background: linear-gradient(90deg,
        rgba(0, 212, 255, 1) 3%,
        rgba(0, 140, 189, 1) 56%,
        rgba(0, 139, 188, 1) 63%,
        rgba(0, 138, 187, 1) 83%,
        rgba(0, 131, 179, 1) 90%,
        rgba(0, 127, 175, 1) 97%,
        rgba(2, 0, 36, 1) 100%,
        rgba(1, 79, 123, 1) 100%,
        rgba(1, 99, 145, 1) 100%);
  }


  

</style>

<!-- Style without Scope -->
<style>

/* Nuzul */
/* Animation class warning red */

.alert-text{
  color:red !important;
  font-weight: bold !important;
}

.alert-text-animation{
  animation: color-change 1s infinite;
  color: #ffa834 !important;
  
}

@keyframes color-change {
  0% { color: red; font-weight: bold; }
  10% { color: rgb(0, 0, 0); font-weight: 300; }
  50% { color: red; font-weight: bold; }
  100% { color: red; font-weight: bold; }
}

</style>